import Axios from "axios";
import AxiosRetry from "axios-retry";
import { LoggingService } from "../shared/services/logging-service";
import { appConfig } from "../config/app-config";

AxiosRetry(Axios, {
  retries: 15, // number of retries
  retryDelay: (retryCount) => {
    return retryCount * 1000; // time interval between retries
  },
  retryCondition: (error) => {
    // true if request should be retried, false otherwise
    return error.response?.status === 503;
  },
});

//Set up for server connections for the test
export const axiosApiProvider = () => {
  const apiProvider = Axios.create({
    baseURL: appConfig().api.baseURL,
  });

  apiProvider.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      LoggingService.ErrorLogger(error);
    }
  );

  return apiProvider;
};

//Set up for Latus connections
export const axiosApiLatusProvider = () => {
  const apiProvider = Axios.create({
    baseURL: appConfig().api.LatusURL,
  });

  apiProvider.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return error;
    }
  );
  return apiProvider;
};

//Set up for Latus connections
export const axiosAdminApiProvider = () => {
  const apiProvider = Axios.create({
    baseURL: appConfig().adminApp.baseURL,
  });

  apiProvider.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return error;
    }
  );
  return apiProvider;
};

//Set up for ApiFastAGP connections
export const axiosApiFastAGProvider = () => {
  const apiProvider = Axios.create({
    baseURL: appConfig().api.FastAGP,
  });

  apiProvider.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return error;
    }
  );
  return apiProvider;
};
